import RightSideBar from '../Components/RightSideBar';
import RequireAuth from '../Components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import ViewAlum from './Alum/AlumProfiles';
import AddAlum from './Alum/AddAlum';
import Alumus from './Alum/Alumus';

function Alum ({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<ViewAlum PF={PF}/>} />
            <Route exact path='/view/:id' element={<Alumus PF={PF}/>} />
            <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
              <Route exact path='/add' element={<AddAlum PF={PF} />} />
            </Route>  
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default Alum