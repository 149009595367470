/* eslint-disable jsx-a11y/anchor-has-content */
import BusinessCategories from '../../Components/CategoryList';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';
import Select from 'react-select';
import { useState } from 'react';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ADD_PROFILE_URL = '/company/add';
const UPLOAD_FILE_URL = '/upsingle';

export default function AddCompany({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [category, setCategory] = useState('');
    const [website, setWebsite] = useState('');
    const [detail, setDetail] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const onbio = (value) => {
        setDetail(value);
    } 

    const addProfile = async(e) => {
        e.preventDefault();
        const profile = {name, email, phone, website, detail, category, userId: authDecode.user.id};

        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png");
                navigate(0);
            }else if (file.size > 700000) {
                alert("File is too large. Upload file less than 700kb");
                navigate(0);
            }else {
                const formData = new FormData();
                const filename = name + '_' + Date.now() + '_' + file.name;            
                formData.append("name", filename);
                formData.append("file", file);
                profile.logo = filename;
        
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                    alert('Image Posted');
                }catch(err){
                    console.log(err);
                }
                
            }
        }
        console.log(profile)
        try {
            axiosPrivate.post(ADD_PROFILE_URL, profile).then(()=>{
                navigate('/company');
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <div className="col-lg-8 mb-3">    
            <div className='main-form'>
                <form className="p-2" onSubmit={addProfile} data-toggle="validator">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="teachers-left mt-50">
                                <div className="hero">
                                    { file && <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{height: '200px', width: '200px'}} alt={name} /> }
                                    <div className="form-group mt-3">
                                        <label htmlFor="fileInput">
                                            <h5>Add Logo: <i className="fa fa-recycle"></i></h5>
                                        </label>
                                        <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                    </div>
                                    <div className="form-group">
                                        <b>Business Category </b>
                                        <Select defaultInputValue={category} options={BusinessCategories} onChange={(e)=>{setCategory(e.value)}} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={email} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="fa fa-phone" /> Phone</b>
                                        <PhoneInput defaultCountry='UG' value={phone} onChange={setPhone}/>
                                        <p className="small">*Example: 772 123 456 or 705 123 456</p>  
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-globe" /> Website</b>
                                        <input type="text" value={website} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setWebsite(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 bg-white border p-3">
                            <div className='form-group mb-5'>
                                <h5 className='mt-3'>Business Name</h5>
                                <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                            </div>
                            <div className='form-group mt-3'>
                                <h5>Business Profile</h5>
                                <ReactQuill theme="snow" rows="15" value={detail} onChange={onbio} />
                            </div>
                            <br />
                            <p className="form-message"></p>
                            <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !email || !phone || !detail || !category || !file }>Send</button>
                            &nbsp;&nbsp;&nbsp;
                            <Link className="btn btn-primary font-weight-semi-bold px-4" to='/company'>Cancel</Link> 
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}