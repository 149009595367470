const BusinessCategories = [
    {label: 'Agriculture', value: 'Agriculture'},
    {label: 'Automotive', value: 'Automotive'},
    {label: 'Consultancy', value: 'Consultancy'},
    {label: 'Computers', value: 'Computers'},
    {label: 'Construction', value: 'Construction'},
    {label: 'Education', value: 'Education'},
    {label: 'Entertainment', value: 'Entertainment'},
    {label: 'Events Planning', value: 'Events Planning'},
    {label: 'Financial', value: 'Financial'},
    {label: 'Healthcare', value: 'Healthcare'},
    {label: 'Machinery', value: 'Machinery'},
    {label: 'Legal', value: 'Legal'},
    {label: 'Manufacturing', value: 'Manufacturing'},
    {label: 'Personal Care', value: 'Personal Care'},
    {label: 'Printing', value: 'Printing'},
    {label: 'Real Estate', value: 'Real Estate'},
    {label: 'Retail', value: 'Retail'},
    {label: 'Transportation', value: 'Transportation'},
    {label: 'Travel', value: 'Travel'},
    {label: 'Wholesale', value: 'Wholesale'},
    {label: 'Miscellaneous', value: 'Miscellaneous'},
]

export default BusinessCategories;