import { axiosPrivate } from '../../Assets/axios';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { useRef, useEffect, useState} from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';

const VIEW_USER_URL = '/users/view/';
const UPLOAD_FILE_URL = '/upsingle';
const EDIT_USER_URL = '/users/edit/';
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

function ViewUser({PF}) {
  const { auth } = useAuth();
  const errRef = useRef();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [userProfile, setUserProfile] = useState({});  
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userTwitter, setUserTwitter] = useState('');
  const [userLinkedIn, setUserLinkedIn] = useState('');
  const [userInstagram, setUserInstagram] = useState('');

  //Editing User
  const [updateProfile, setUpdateProfile] = useState(false);
  const [changePwd, setChangePwd] = useState(false);
  const [file, setFile] = useState(null);

  //User submitted content
  const [userStories, setUserStories] = useState([]);
  const [userArticles, setUserArticles] = useState([]);

  const [oldPwd, setOldPwd] = useState('');
  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  let {id} = useParams();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_USER_URL + id).then((response)=>{
      if(isMounted){
        setUserProfile(response.data.foundUser); 
        setUserName(response.data.foundUser.name);
        setUserPhone(response.data.foundUser.telephone);
        setUserTwitter(response.data.foundUser.twitter);
        setUserLinkedIn(response.data.foundUser.linkedIn);
        setUserInstagram(response.data.foundUser.instagram);
        setUserStories(response.data.userStories); 
        setUserArticles(response.data.userArticles); 
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
      setErrMsg('');
  }, [pwd, matchPwd])

  const handleUpdateUser = async(e) => {

    e.preventDefault();
    const updatedUser = {
      name: userName,
      telephone: userPhone,
      twitter: userTwitter,
      linkedIn: userLinkedIn,
      instagram: userInstagram,
    };
  
    if(file){
      const formData = new FormData();
      const filename = Date.now() + file.name;
        
      formData.append("name", filename);
      formData.append("file", file);
      updatedUser.picture = filename;
  
      try{
        await axiosPrivate.post(UPLOAD_FILE_URL, formData);
      }catch(err){ console.log(err); }
    }
      
    try {
      axiosPrivate.put(EDIT_USER_URL + userProfile.id, updatedUser).then(()=>{
        setUpdateProfile(false)
        alert('Profile Edited Successfully');
        navigate(`/user/view/${userProfile.id}`);
      }).catch( function (error) { console.log(error.config); });   
    }catch (error) { console.log(error); }
  }

  const handleChangePwd = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v2 = PWD_REGEX.test(pwd);
    if (!v2) {
      setErrMsg("Invalid Entry");
      return;
    }

    const updatedUserPwd = {oldPwd, pwd}
    try {
      await axiosPrivate.put(EDIT_USER_URL + userProfile.id, updatedUserPwd);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setOldPwd('');
      setPwd('');
      setMatchPwd('');
      setChangePwd(false);
      alert('Password Updated Successfully');
      navigate(`/user/view/${userProfile.id}`);
    } catch (err) {
      errRef.current.focus();
    }
  }

  return (
    <div className="col-lg-8 mb-3">
      <div className="col-12">
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <div className="section-title">
          <h4 className="m-0 text-uppercase font-weight-bold">{userName}</h4>
          {authDecode && (authDecode?.user?.id === userProfile.id || authDecode?.user?.role >=4) ? (
            <span>
              &nbsp;&nbsp;<Link className='text-uppercase text-body' to='#' onClick={() => setUpdateProfile(true)}><i className='fa fa-cog font-weight-bold' /></Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Link className='text-uppercase text-body' to='#' onClick={() => {setUpdateProfile(false); setChangePwd(true)}}><i className='fa fa-key font-weight-bold' /></Link>
            </span>
          ) : (<></>)}
        </div>
      </div>
      <div className="row mx-0 mb-3 p-2">
        <div className="col-lg-5 position-relative overflow-hidden px-0">
          { file ? (
            <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{objectFit: "cover", height: '200px', width: '200px'}} alt="" />
          ):(
            userProfile?.picture && <img className='rounded-circle mr-2' src={PF + userProfile.picture} style={{objectFit: "cover", height: '200px', width: '200px'}} alt={userProfile?.name} />
          )}
        </div>
        <div className="col-lg-7 position-relative overflow-hidden bg-white border p-3">
          { updateProfile ? (
            <form className="p-2">
              <div className="form-group">
                <label htmlFor="fileInput">
                  Change Profile Picture: <i className="writeIcon fas fa-recycle"></i>
                </label>
                <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
              </div>
              <div className="form-group">
                Name: 
                <input type="text" value={userName ? userName : ''} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setUserName(e.target.value)} />
              </div>
              <div className="form-group">
                Phone: 
                <input type="text" value={userPhone ? userPhone : ''} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setUserPhone(e.target.value)} />
              </div>
              <div className="form-group">
                Twitter: 
                <input type="text" value={userTwitter ? userTwitter : ''} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setUserTwitter(e.target.value)} />
              </div>
              <div className="form-group">
                LinkedIn: 
                <input type="text" value={userLinkedIn ? userLinkedIn : ''} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setUserLinkedIn(e.target.value)} />
              </div>
              <div className="form-group">
                Instagram: 
                <input type="text" value={userInstagram ? userInstagram : ''} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setUserInstagram(e.target.value)} />
              </div>
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={handleUpdateUser}>Update</button>
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={() => setUpdateProfile(false)}>Cancel</button>  
            </form>
          ) : changePwd ? (
            <form className="p-2">
              <div className="form-group">
                {/** Password **/}
                <label htmlFor="oldpwd">
                  Old Password:
                </label><br />
                <input type="password" id="oldpwd" autoFocus={true} className="p-2" required="required" onChange={(e)=>setOldPwd(e.target.value)} />
              </div>
              <div className="form-group">
                {/** Password **/}
                <label htmlFor="password">
                  New Password:
                  <i className={validPwd ? "valid singleIcon far fa-check" : "hide singleIcon far  fa-check"} />
                  <i className={validPwd || !pwd ? "hide singleIcon far fa-times" : "invalid singleIcon far fa-times"} />
                </label><br />
                <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} className="p-2" required aria-invalid={validPwd ? "false" : "true"} aria-describedby="pwdnote" onFocus={() => setPwdFocus(true)} onBlur={() => setPwdFocus(false)} />
                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                  <i className='singleIcon far fa-info-circle' />
                  8 to 24 characters.<br />
                  Must include uppercase and lowercase letters, a number and a special character.<br />
                  Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                </p>
              </div>
              <div className="form-group">
                {/**Confirm Password **/}
                <label htmlFor="confirm_pwd">
                  Confirm New Password:
                  <i className={validMatch && matchPwd ? "valid singleIcon far fa-check" : "hide singleIcon far fa-check"} />
                  <i className={validMatch || !matchPwd ? "hide singleIcon far fa-times" : "invalid singleIcon far fa-times"} />
                </label><br />
                <input type="password" id="confirm_pwd" onChange={(e) => setMatchPwd(e.target.value)} className="p-2" value={matchPwd} required aria-invalid={validMatch ? "false" : "true"} aria-describedby="confirmnote" onFocus={() => setMatchFocus(true)} onBlur={() => setMatchFocus(false)} />
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                  <i className='far fa-info-circle' />
                  Must match the first password input field.
                </p>
              </div>
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={handleChangePwd} disabled={!validPwd || !validMatch ? true : false}>Change</button>
              &nbsp;&nbsp;&nbsp;
              <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} type="submit" onClick={() => setChangePwd(false)}>Cancel</button>  

            </form>
          ) : (
            <>
              <br/>
              <b>Phone:</b> {userPhone}<br/><br/>
              <b>Twitter:</b> {userTwitter}<br/><br/>
              <b>LinkedIn:</b> {userLinkedIn}<br/><br/>
              <b>Instagram:</b> {userInstagram}
            </>
          )}
        </div>
      </div>
      { (userStories.length>0 || userArticles.length>0) &&
        <div className="col-12">
          <div className="section-title">
            <h6 className="mb-1 font-weight-bold text-secondary text-uppercase">
              News & Articles by&nbsp; 
              <span className='text-capitalize'>{userName}</span>
            </h6>
          </div>
          <div className="bg-white border border-top-0 p-4">
            { userStories.length>0 && <>
              <h5 className='font-weight-bold'>News Stories</h5><hr />
              {userStories.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>   
                    <small className='badge badge-primary text-uppercase p-1 mr-2'> {new Date(value.createdAt).toDateString()}</small>
                    &nbsp;-&nbsp; 
                    <Link className="text-secondary text-capitalize" to={`/news/view/${value.id}`}>{value.title}</Link>
                  </div>
                )
              })}
            </>}
            { userArticles.length>0 && <>
              <br /><h5 className='font-weight-bold'>Articles</h5><hr />
              { userArticles.map((value) => {
                return (
                  <div key={value.id} className='mx-0 mb-3'>
                    <small className='badge badge-primary text-uppercase p-1 mr-2'>{new Date(value.createdAt).toDateString()}</small>
                    &nbsp;-&nbsp;
                    <Link className="text-secondary text-capitalize" to={`/articles/view/${value.id}`}>{value.title}</Link>
                  </div>
                )
              })}
            </>}
          </div>
        </div>
      }
    </div>
  )
}

export default ViewUser