const HouseList = [
    {label: 'Australia', value: 'Australia'}, 
    {label: 'Canada', value: 'Canada'}, 
    {label: 'England', value: 'England'}, 
    {label: 'Ghana', value: 'Ghana'}, 
    {label: 'Grace', value: 'Grace'}, 
    {label: 'Mutesa', value: 'Mutesa'}, 
    {label: 'Nigeria', value: 'Nigeria'}, 
    {label: 'Sabaganzi', value: 'Sabaganzi'}, 
    {label: 'S. Africa', value: 'S. Africa'}
]

export default HouseList;