import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {Link, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import { sanitize } from 'dompurify';

const PAGES_URL = '/pages';

function AboutPage({PF}) {
  const axiosPrivate = useAxiosPrivate();
  const [pages, setPages] = useState({});
  const [about, setAbout] = useState({});
  const navigate = useNavigate();  
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(PAGES_URL).then((response) => {
      if(isMounted){
        setPages(response.data.pages)
        setAbout(response.data);

      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <div className="section-title">
      <h4 className="m-0 text-uppercase font-weight-bold">About The Club</h4>
    </div>
    <div className="col-lg-12 mb-3">
      <div className='row'>
        {pages.length>0 && pages.map((index)=>{
          return(
            index.id%2===0 ? (
              <div key={index.id} className='row'>
                <div className="col-lg-6 pb-4">
                  <p className="paraAbout p-2" dangerouslySetInnerHTML={{ __html: sanitize(index.pageText)}} />
                  <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to={`/about/${index.id}`}>Read More</Link>
                </div>
                <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "400px"}} onClick={() => navigate(`/about/${index.id}`)}>
                  <img className='img-fluid h-100' src={PF + index.picture} style={{objectFit: "cover"}} alt=''/>
                  <div className='overlay'>
                    <h2 className="text-white text-capitalize font-weight-bold">{index.title}</h2>
                  </div>
                </div>                        
              </div>
            ):(
              <div key={index.id} className='row'>
                <div className="col-lg-6 position-relative overflow-hidden px-0" style={{height: "400px"}} onClick={() => navigate(`/about/${index.id}`)}>
                  <img className='img-fluid h-100' src={PF + index.picture} style={{objectFit: "cover"}}  alt=''/>
                  <div className='overlay'>
                    <h2 className="text-white text-capitalize font-weight-bold">{index.title}</h2>
                  </div>
                </div>
                <div className="col-lg-6 pb-4">
                  <p className="paraAbout" dangerouslySetInnerHTML={{ __html: sanitize(index.pageText)}} />
                  <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to={`/about/${index.id}`}>Read More</Link>
                </div>
              </div>
            )
          )
        })}
        <div className="row mt-3">
          <div className="col-12">
            <div className="section-title">
              <h4 className="m-0 text-uppercase font-weight-bold">Leadership Teams</h4>
            </div>
          </div>               
          <div className="col-lg-4">
            <div className="position-relative mb-3">
              <img className="img-fluid w-100" src={PF +  about?.trustee?.profile?.pictures} style={{objectFit: 'cover'}} alt="" />
              <div className="bg-white border border-top-0 p-2">
                <Link className="h6 d-block text-secondary text-uppercase font-weight-bold" to="/about/trustees">Trustees</Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="position-relative mb-3">
              <img className="img-fluid w-100" src={PF +  about?.obc?.profile?.pictures} style={{objectFit: 'cover'}} alt=""/>
              <div className="bg-white border border-top-0 p-2">
                <Link className="h6 d-block text-secondary text-uppercase font-weight-bold" to="/about/exco">Executive Committee</Link>
              </div>    
            </div>
          </div>                                      
          <div className="col-lg-4">
            <div className="position-relative mb-3">
              <img className="img-fluid w-100" src={PF +  about?.obuc?.profile?.pictures} style={{objectFit: 'cover'}} alt=""/>
              <div className="bg-white border border-top-0 p-2">
                <Link className="h6 d-block text-secondary text-uppercase font-weight-bold" to="/about/obuc">University Chapter</Link> 
              </div>    
            </div>
          </div>                    
        </div>
      </div>
    </div>
  </>)
}

export default AboutPage