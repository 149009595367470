import { Link } from 'react-router-dom';

function HomeProfiles({PF, profiles}) {
    return (
        <>   
            { profiles.length>0 && 
                <>
                    <div className="col-12">
                        <div className="section-title">
                        <h4 className="m-0 text-uppercase font-weight-bold">Alumni Profiles</h4>
                        <Link className="text-secondary font-weight-medium text-decoration-none" to="/profiles">View All</Link>
                        </div>
                    </div>
                    { profiles.map((profile)=>{
                        return (
                            <div className="col-lg-6" key={profile.id}>
                                <div className="position-relative mb-3">
                                    <img className="img-fluid w-100" src={PF + profile.pictures} style={{objectFit: 'cover', height: '200px'}} alt="" />
                                    <div className="bg-white border border-top-0 p-2">
                                        <div className="mb-2">
                                            <small>{new Date(profile.updatedAt).toDateString()}</small>
                                        </div>
                                        <Link className="h5 d-block mb-2 text-secondary text-capitalize font-weight-bold" to={`/profiles/view/${profile.id}`}>{profile.profileTitle}</Link>
                                        <p className='m-0 paraLimit2'>{profile.profileText}</p>
                                    </div>
                                </div>
                            </div>    
                        )
                    })}
                </>
            }

        </>
    )
}

export default HomeProfiles
