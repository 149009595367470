/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useParams, useNavigate } from 'react-router-dom';
import BusinessCategories from '../../Components/CategoryList';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import jwtDecode from 'jwt-decode';
import Select from 'react-select';
import { sanitize } from 'dompurify';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DELETE_PROFILE_URL = '/company/delete/';
const EDIT_PROFILE_URL = '/company/edit/';
const VIEW_PROFILE_URL = '/company/view/';
const UPLOAD_FILE_URL = '/upsingle';
const DELETE_FILE_URL = '/fileDelete/';

export default function ViewCompany({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [updateMode, setUpdateMode] = useState(false);
    const [category, setCategory] = useState('');
    const [website, setWebsite] = useState('');
    const [profile, setProfile] = useState({});  
    const [detail, setDetail] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');

    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            if(isMounted){
                setProfile(response.data);
                setName(response.data.name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setDetail(response.data.detail);
                setWebsite(response.data.website);
                setCategory(response.data.category);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deleteProfile = async(id) => {
        try {
            axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                console.log('Old image deleted');
            }).catch(function(error){
                console.log(error.config);
            });

            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Company Profile Deleted');
                navigate('/company');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch(error){
            console.log(error);
        }
    };

    const onbio = (value) => {
        setDetail(value);
    } 

    const updateProfile = async(e) => {
        e.preventDefault();
        const updatedProfile = {name, email, phone, website, detail, category, userId: authDecode.user.id, id: profile.id};
        const profileId = profile.id;

        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png");
                navigate(0);
            }else if (file.size > 700000) {
                alert("File is too large. Upload file less than 700kb");
                navigate(0);
            }else {
                axiosPrivate.post(DELETE_FILE_URL + profile.picture).then(()=>{
                    console.log('Old image deleted');
                }).catch(function(error){
                    console.log(error.config);
                });

                const formData = new FormData();
                const filename = name + '_' + Date.now() + '_' + file.name;            
                formData.append("name", filename);
                formData.append("file", file);
                updatedProfile.logo = filename;
        
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                    alert('Image Posted');
                }catch(err){
                    console.log(err);
                }

            }
        }
        
        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Company Profile Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <div className="col-lg-8 mb-3">    
            { updateMode ? 
                <div className='main-form'>
                    <form className="p-2" onSubmit={updateProfile} data-toggle="validator">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="teachers-left mt-50">
                                    <div className="hero d-flex align-items-center" style={{height: '200px', width: '200px'}}>
                                        { file ? (
                                            <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{objectFit: 'cover', height: '200px',  width: '200px'}} alt={name} />
                                        ): (
                                            profile?.logo && <img className='rounded-circle mr-2 img-fluid h-100 w-100' src={PF + profile?.logo}  style={{objectFit: 'cover', height: '200px',  width: '200px'}} alt={name} />
                                        )}
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="fileInput">
                                            <h5>Change Logo: <i className="fa fa-recycle"></i></h5>
                                        </label>
                                        <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                    </div>
                                    <div className="form-group">
                                        <b>Business Category </b>
                                        <Select defaultInputValue={category} options={BusinessCategories} onChange={(e)=>setCategory(e.value)} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={email} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setEmail(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <b><i className="fa fa-phone" /> Phone</b>
                                        <PhoneInput defaultCountry='UG' value={phone} onChange={setPhone}/>
                                        <p className="small">*Example: 772 123 456 or 705 123 456</p>  
                                    </div>
                                    <div className="form-group">
                                        <b><i className="icon fa fa-envelope-o" /> Email</b>
                                        <input type="text" value={website} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setWebsite(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 bg-white border p-3">
                                <div className='form-group mb-5'>
                                    <h5 className='mt-3'>Business Name</h5>
                                    <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                                </div>
                                <div className='form-group mt-3'>
                                    <h5>Business Profile</h5>
                                    <ReactQuill theme="snow" rows="15" value={detail} onChange={onbio} />
                                </div>
                                <br />
                                <p className="form-message"></p>
                                <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !email || !phone || !detail || !category }>Send</button>
                                &nbsp;&nbsp;&nbsp;
                                <Link className="btn btn-primary font-weight-semi-bold px-4" to='#cancel' onClick={(e)=>setUpdateMode(false)} >Cancel</Link> 
                            </div>
                        </div>
                    </form>
                </div>
            :                    
                <div className="justify-content-center">
                    <div className='row'>
                        <div className="col-lg-4 col-md-8">
                            <div className="teachers-left mt-50">
                                <div className="hero">
                                    { file ? (
                                        <img className='rounded-circle mr-2' src={URL.createObjectURL(file)} style={{objectFit: 'cover', height: '200px', width: '200px'}} alt={name} />
                                    ): (
                                        profile?.logo && <img className='rounded-circle mr-2' src={PF + profile?.logo} style={{objectFit: 'cover', height: '200px', width: '200px'}} alt={name} />
                                    )}
                                </div>
                                <div className='blog-details mt-3'>
                                    <div className="cont name">
                                        <h5>Business Category</h5>
                                        {category}<br /><br />
                                        <h5>Contact Details</h5>
                                        <i className="fa fa-phone" aria-hidden="true" /> {phone}<br /><br />
                                        <i className="fa fa-envelope" aria-hidden="true" /><Link to='#email' onClick={(e)=>{ window.location = `mailto:${email}`}}> {email}</Link><br/><br />
                                        <i className="fa fa-globe" aria-hidden="true" /> <a target="_blank" rel="noreferrer" href={`https://${website}`}> {website}</a><br />
                                        { (authDecode && authDecode?.user?.role >=4) &&
                                            <div className='row mt-2'>
                                                <div className='col-9'>&nbsp;</div>
                                                <div className='col-3'>
                                                    <Link to='#edit'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/></Link>
                                                    &nbsp;&nbsp;
                                                    <Link to='#delete'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteProfile(profile.id)} /></Link>
                                                </div>

                                            </div>  
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 bg-white border p-3">
                            <h2 className='mb-3'>{profile?.name}</h2>
                            <div className="dashboard-cont pt-20" dangerouslySetInnerHTML={{ __html: sanitize(detail)}} style={{whiteSpace: "pre-line"}} />
                        </div>
                    </div>
                </div> 
            }
        </div>
    )
}