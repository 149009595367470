/* eslint-disable jsx-a11y/anchor-has-content */
import {Link, useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import HouseList from '../../Components/HouseList';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { sanitize } from 'dompurify';
import jwtDecode from 'jwt-decode';
import Select from 'react-select';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DELETE_PROFILE_URL = '/alumni/delete/';
const EDIT_PROFILE_URL = '/alumni/edit/';
const VIEW_PROFILE_URL = '/alumni/view/';

function Alumus({PF}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [updateMode, setUpdateMode] = useState(false);
    const [entryYear, setEntryYear] = useState('');
    const [exitYear, setExitYear] = useState('');
    const [classOf, setClassOf] = useState('');
    const [profile, setProfile] = useState({});
    const [detail, setDetail] = useState('');
    const [house, setHouse] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PROFILE_URL + id).then((response)=>{
            if(isMounted){
                setProfile(response.data);
                setEntryYear(response.data.entryYear);
                setExitYear(response.data.exitYear);
                setClassOf(response.data.classOf);
                setDetail(response.data.profile);
                setHouse(response.data.house);
                setName(response.data.name);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deleteProfile = async(id) => {
        try {
            axiosPrivate.delete(DELETE_PROFILE_URL + id).then(()=>{
                alert('Alumnus Profile Deleted');
                navigate('/alum');
            }).catch(function(error){
                console.log(error.config);
            });               
        } catch(error){
            console.log(error);
        }
    };

    const onDetail = (value) => {
        setDetail(value);
    } 

    const updateProfile = async(e) => {
        e.preventDefault();
        const updatedProfile = {name, house, entryYear, exitYear, classOf, profile: detail, userId: authDecode.user.id, id: profile.id};
        const profileId = profile.id;
        
        try {
            axiosPrivate.put(EDIT_PROFILE_URL + profileId, updatedProfile).then(()=>{
                alert('Alumni Profile Edited');
                setUpdateMode(false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    const date = new Date();
    const year = date.getFullYear();
    let classYear = [];

    for (var i = 1906; i <= year-1; i++) {
        classYear.push({label: i, value: i})
    }

    // let houseList = [
    //     {label: 'Australia', value: 'Australia'}, 
    //     {label: 'Canada', value: 'Canada'}, 
    //     {label: 'England', value: 'England'}, 
    //     {label: 'Ghana', value: 'Ghana'}, 
    //     {label: 'Grace', value: 'Grace'}, 
    //     {label: 'Mutesa', value: 'Mutesa'}, 
    //     {label: 'Nigeria', value: 'Nigeria'}, 
    //     {label: 'Sabaganzi', value: 'Sabaganzi'}, 
    //     {label: 'S. Africa', value: 'S. Africa'}
    // ];

    return (
        <div className="col-lg-8 mb-3">    
            { updateMode ? 
                <div className='main-form'>
                    <form onSubmit={updateProfile} data-toggle="validator">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group section-title">
                                    <h5>Name: </h5>&nbsp;&nbsp;
                                    <input type="text" value={name} autoFocus={true} className="form-control p-2" required="required" onChange={(e)=>setName(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <br /><b>House: </b>
                                    <Select defaultInputValue={house} options={HouseList} onChange={(e)=>setHouse(e.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <br /><b>Class of: </b>
                                    <Select defaultInputValue={classOf} options={classYear} onChange={(e)=>setClassOf(e.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <br /><b>Year of Entry: </b>
                                    <Select defaultInputValue={entryYear} options={classYear} onChange={(e)=>setEntryYear(e.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <br /><b>Year of Exit: </b>
                                    <Select defaultInputValue={exitYear} options={classYear} onChange={(e)=>setExitYear(e.value)} />
                                </div>
                            </div>
                            <div className="col-lg-12 mt-50">
                                <br /><h5>Profile: </h5>
                                <div className='form-group'>
                                    <ReactQuill theme="snow" rows="10" value={detail} onChange={onDetail} />
                                </div>

                            </div>
                        </div>
                        <br />
                        <p className="form-message"></p>
                        <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" disabled={!name || !house}>Send</button>
                        &nbsp;&nbsp;&nbsp;
                        <Link className="btn btn-primary font-weight-semi-bold px-4" to='#cancel' onClick={(e)=>setUpdateMode(false)} >Cancel</Link> 
                    </form>
                </div>
            :                    
                <div className="row justify-content-center">
                    <div className='col-lg-12'>
                        <h2 className="mb-5 text-secondary text-capitalize font-weight-bold">{name}</h2>
                    </div>
                    <div className="col-lg-3 p-3">
                        <div className="teachers-left">
                            {/* <div className='blog-details'>
                                <div className="cont name"> */}
                                    House: <b>{house}</b><br/><br/>
                                    Class of: <b>{classOf}</b><br/><br/>
                                    Years in Budo<br />
                                    <b>{entryYear} - {exitYear}</b><br/><br/>
                                    { (authDecode && authDecode?.user?.role >=4) &&
                                        <div className='singleEdit'>
                                            <i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/>
                                            &nbsp;&nbsp;
                                            <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteProfile(profile.id)} />
                                        </div>
                                    }
                                {/* </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-9 bg-white border p-3">
                        <div className="teachers-right">
                                <h6><b>Profile</b></h6>
                            <div className="tab-content">
                                <div className="tab-pane fade show active">
                                    <p className="dashboard-cont pt-20" dangerouslySetInnerHTML={{ __html: sanitize(detail)}} style={{whiteSpace: "pre-line"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Alumus
