import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import useAuth from '../Hooks/useAuth';
import jwtDecode from 'jwt-decode';
import Advert from './Advert';

const VIEW_RSIDEBAR_URL = 'home/rsidebar';
const VIEW_ORDERS_URL = 'shop/listOrders/';

function RightSideBar({PF}) {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const axiosPrivate = useAxiosPrivate();
  const [sideOrders, setSideOrders] = useState([]);  
  const [sideNews, setSideNews] = useState([]);  
  const [sideEvents, setSideEvents] = useState([]);  
  const [sideMerch, setSideMerch] = useState([]);  

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if(authDecode){
      axiosPrivate.get(VIEW_ORDERS_URL + authDecode.user.id).then((response)=>{
        isMounted && setSideOrders(response.data);

      }).catch((error) => {
        //Handle Errors Coming out of this
      });
    }
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_RSIDEBAR_URL).then((response)=>{
      if(isMounted){
        setSideNews(response.data.stories);
        setSideEvents(response.data.calEvents);
        setSideMerch(response.data.merch);  
      }
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);
  
  return (
    <div className="col-lg-4">
      {/*Oustanding Orders */ }
      {sideOrders.length>0 &&
        <div className="mb-5">
          <div className="section-title mb-3">
            <h4 className="m-0 text-uppercase font-weight-bold">Oustanding Orders</h4>
          </div>
          {sideOrders.map((item)=>{
            return(
              <div key={item.id} className='mx-0 mb-3'>          
                  <small className='badge badge-primary text-uppercase p-1 mr-2'>{new Date(item.updatedAt).toDateString()}</small> - &nbsp;&nbsp; 
                  <Link className="text-secondary text-capitalize" to={`/shop/payment/${item.orderCode}`}>{item.orderCode}</Link>
                  &nbsp;&nbsp;- {item.amount.toLocaleString('en')}/- 
              </div>
            )
          })}
        </div>
      }

      {/*Advertisement */}
      <Advert section='0' PF={PF} />

      {/*Trending News */ }
      {sideNews.length>0 &&
        <div className="mb-3">
          <div className="section-title mb-3">
            <h4 className="m-0 text-uppercase font-weight-bold">Most Read Stories</h4>
          </div>
          {sideNews.map((item)=>{
            return(
              <div key={item.id} className="d-flex align-items-center bg-white mb-3 overflow-hidden" style={{height: '120px'}}>
                <img className="img-fluid h-100" src={PF + item.picture} style={{objectFit: 'cover', width: '150px'}} alt="" />
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <small>{new Date(item.createdAt).toDateString()}</small>
                  </div>
                  <Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/news/view/${item.id}`}>{item.title}</Link>
                </div>
              </div>
            )
          })}
        </div>
      }

      {/*Upcoming Events */} 
      { sideEvents.length>0 && 
        <div className="mb-3">
          <div className="section-title mb-3">
            <h4 className="m-0 text-uppercase font-weight-bold">Upcoming Events</h4>
          </div>
          {sideEvents.map((item)=>{
            return(
              <div key={item.id} className="d-flex align-items-center bg-white mb-3 overflow-hidden" style={{height: '120px'}}>
                <img className="img-fluid h-100" src={PF + item.picture} style={{width: '150px'}} alt="" />
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center border border-left-0">
                  <div className="mb-2">
                    <Link className="badge badge-primary text-uppercase font-weight-semi-bold p-1 mr-2" to={`/events/view/${item.id}`}>{item.location}</Link><br />
                    <small className="text-body">{new Date(item.date).toDateString()}</small>
                  </div>
                  <Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/events/view/${item.id}`}>{item.title}</Link>
                </div>
              </div>
            )
          })}
        </div>
      }
          
      {/* Bestsellers  */}
      {sideMerch.length>0 && 
        <div className="mb-3">
          <div className="section-title mb-3">
            <h4 className="m-0 text-uppercase font-weight-bold">Bestsellers</h4>
          </div>
          {sideMerch.map((item)=>{
            let image = item.images.split(',')
            return(
              <div key={item.id} className="d-flex align-items-center bg-white mb-3 overflow-hidden" style={{height: '120px'}}>
                <img className="img-fluid h-100" src={PF + image[0]} style={{width: '280px', objectFit: 'cover' }} alt="" />
                <div className="w-100 h-100 px-3 d-flex flex-column justify-content-center">
                  <Link className="h6 m-0 text-secondary text-capitalize font-weight-bold" to={`/shop/product/${item.id}`}>{item.title}</Link>
                  <small className="text-body">{item.price.toLocaleString('en')}</small>
                </div>
              </div>
            )
          })}
        </div>
      }
      
    </div>
  )
}

export default RightSideBar