import BusinessCategories from '../../Components/CategoryList';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import Pagination from '../../Components/Pagination';
import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Select from 'react-select';

const VIEW_ALUMNI_URL = '/company/';

function Companies({PF}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    const [itemsPerPage, setItemsPerPage] = useState(40);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [alumni, setAlumni] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [category, setCategory] = useState('');
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ALUMNI_URL + '?page=' + page + '&category='+ category + '&q='+ searchQuery + '&items=' + itemsPerPage).then((response) => {
            if(isMounted){
                setAlumni(response.data.docs);
                setTotalPages(response.data.pages);
            }
        }).catch((error) => {
            console.log(error.message)
        })
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [page, itemsPerPage, category, searchQuery]);

    console.log(alumni)

    return (
        <div className="col-lg-8 mb-3">
            <div className="section-title">
                <h4 className="m-0 text-uppercase font-weight-bold">Business Directory</h4>
                {(authDecode && authDecode?.user?.role >=3) && 
                    <Link className="text-secondary font-weight-medium text-decoration-none" to="/company/add">
                        <i className="writeIcon fas fa-plus"></i> Add Company
                    </Link>
                }
            </div>
            <div className="container">
                <div className='pb-30 row'>
                    <div className='p-2 col-lg-5'>
                        <input className='p-2' name="searchQuery" type="text" placeholder="Search" data-error="Name is required." required="required" onChange={(e)=>{setSearchQuery(e.target.value); setPage(1)}}/>
                    </div>
                    <div className='p-2 col-lg-3'>
                        <Select placeholder="Category" options={BusinessCategories} onChange={(e)=>{setCategory(e.value); setPage(1)}} />
                    </div>   
                    <div className="row p-2 col-lg-4">
                        <div className='p-2'>Items Per Page:</div>
                        <div style={{width: '60px'}}> 
                            <select className='p-2' name="itemsPerPage" value={itemsPerPage} onChange={(e) => {setItemsPerPage(e.target.value); setPage(1)}}>
                                <option value="40">40</option>
                                <option value="100">100</option>
                                <option value="200">100</option>
                            </select> 
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                {alumni.length>0 && alumni.map((value)=>{
                    return (
                        <div className="mb-2 col-lg-6" key={value.id}>
                            <img className='rounded-circle mr-2' src={PF + value.logo} style={{objectFit: "cover",height: '60px', width: '60px'}} alt="Alumus"/>
                            <Link className="text-secondary font-weight-medium text-decoration-none" to={`/company/view/${value.id}`}>{value.name}</Link>
                        </div>
                    );
                })}
                </div>
                {totalPages>1 &&
                    <Pagination totalPages={totalPages} setPage={setPage}/>
                }
            </div>
        </div>
    )
}

export default Companies