import { Link, useParams, useNavigate} from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import SocialShare from '../../Components/SocialShare';
import useAuth from '../../Hooks/useAuth';
import {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';
import { sanitize } from 'dompurify';


//Editor
import EditorToolbar, { modules, formats } from "../../Components/EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../css/TextEditor.css";


const VIEW_PAGE_URL = '/pages/view/';
const DELETE_PAGE_URL = '/pages/delete/';
const EDIT_PAGE_URL = '/pages/edit/';
const UPLOAD_FILE_URL = '/upsingle';

function SingleContent({PF}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [page, setPage] = useState({});  
    const [title, setTitle] = useState('');  
    const [pageText, setPageText] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [file, setFile] = useState(null);  

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    let {id} = useParams();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PAGE_URL + id).then((response)=>{
            if(isMounted){
                setPage(response.data);
                setTitle(response.data.title);
                setPageText(response.data.pageText);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [id]);

    const deletePage = async(id) => {
        try {
            axiosPrivate.delete(DELETE_PAGE_URL+id).then(()=>{
                alert('Page Deleted');
                navigate('/about');
            });               
        } catch (error) {    }
    };

    const onPageText = (value) => {
        setPageText(value);
    }

    const updatePage = async(e) => {
        e.preventDefault();
        const updatedPage = {title, pageText, id: page.id};
        const pageId = page.id;

        if(file){
            const formData = new FormData();
            const filename = Date.now() + file.name;          
            formData.append("name", filename);
            formData.append("file", file);
            updatedPage.picture = filename;
      
            try{
              await axiosPrivate.post(UPLOAD_FILE_URL, formData);
            }catch(err){
              console.log(err);
            }
        }
        
        try {
            axiosPrivate.put(EDIT_PAGE_URL + pageId, updatedPage).then(()=>{
                alert('Page Edited');
                setUpdateMode (false);
                window.location.reload();
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    const currentPage = window.location.href;

    return (
        <div className="col-lg-8">
            { updateMode ? (
                <div className="section-title mb-3">
                    <h4 className="m-0 text-uppercase font-weight-bold">Edit Page: {page.title}</h4>
                </div>
            ):(<>
                <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">{page.title}</h2>
                <SocialShare currentPage={currentPage} title={page?.title}/>
                {authDecode && authDecode.user.role >= 4 ? 
                    (<div className="singleEdit">
                        <i className="singleIcon far fa-edit mr-2" aria-hidden="true" onClick={() => setUpdateMode(true)}/>
                        <i className="singleIcon far fa-trash-alt" aria-hidden="true" onClick={() => deletePage(page.id)} />
                    </div>):(<></>)
                }                    
            </>)}
            <div className="bg-white border border-top-0">
                { file ? (
                    <img className="img-fluid w-100" src={URL.createObjectURL(file)} alt="" />
                ): (
                    page?.picture && <img src={PF + page.picture} alt="" className="img-fluid w-100" style={{height: '500px', objectFit: 'cover'}} />
                )}
                {updateMode ? (
                    <form className="p-2">
                        <div className="form-group">
                            <label htmlFor="fileInput">
                                Change Image: <i className="writeIcon fas fa-recycle" />
                            </label>
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                        </div>
                        <div className="form-group">
                            Title: 
                            <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                        </div>
                        <div className="form-group">
                            Page Details:
                            <EditorToolbar toolbarId={'t1'} />
                            <ReactQuill
                                theme="snow" rows="10" modules={modules('t1')} formats={formats}
                                value={pageText}
                                onChange={onPageText}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary font-weight-semi-bold px-4" onClick={updatePage}>Update</button>
                        &nbsp;&nbsp;&nbsp;  
                        <Link className="btn btn-primary font-weight-semi-bold px-4" onClick={(e)=>setUpdateMode(false)} to={`/about/${id}`}>Cancel</Link> 
                    </form> 
                ) : (<div className="mt-3 p-3" dangerouslySetInnerHTML={{ __html: sanitize(page.pageText)}} style={{whiteSpace: "pre-line"}} />)}
            </div>
        </div>
    )
}

export default SingleContent
