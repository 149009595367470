import RightSideBar from '../Components/RightSideBar';
import RequireAuth from '../Components/RequireAuth';
import {Routes, Route} from 'react-router-dom';
import ViewCompany from './Company/ViewCompany';
import AddCompany from './Company/AddCompany';
import Companies from './Company/Companies';

function Company ({PF}) {
  return (
    <div className='container-fluid pt-5 mb-3'>
      <div className="container">
        <div className='row'>
          <Routes>
            <Route exact path='/' element={<Companies PF={PF}/>} />
            <Route exact path='/view/:id' element={<ViewCompany PF={PF}/>} />
            <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
              <Route exact path='/add' element={<AddCompany PF={PF} />} />
            </Route>    
          </Routes>
          <RightSideBar PF={PF} />
        </div>
      </div>
    </div>
  )
}

export default Company